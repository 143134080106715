@charset "utf-8";
/* CSS document written by Harish Kohli */
@media all and (max-width:1024px)
{
.resp-currency { width: 15%;}	
.resp-currency .col-sm-3 { width:100%; padding:0;}
.resp-currency .col-sm-7 { width:100%;}
}


@media all and (max-width:767px){
body{}
.wrap{ width:100%!important; display:table;}
.content {font-size:11px; line-height:16px; text-align:justify;}
.content p{ line-height:18px; font-size:13px;}
.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9, .col-sm-1Forth, .col-sm-1Third, .col-sm-2Third, .col-sm-3Forth{ width:100%!important;}
.mobRow{ width:100%;}
.mobPaddingNone{ padding:0px!important;}
.mobMarginNone{ margin:0px!important;}
.mobmarginBtm30{ margin-bottom:30px;}
.mobAlignCenter{ text-align:center;}
.mobAlignJustify{ text-align:justify;}
.mobAlignLeft{ text-align:left;}
.mobmarginTop{ margin-top:10px;}
.mobmarginTopSM{ margin-top:5px;}
.mobmarginBottom{ margin-bottom:10px;}
.mobDivCenter{ margin:0 25% 10px; float:none; display:block; width:50%;}
.mobwidth{margin:0 15% 10px; width:70%;}
.moblineheight{ line-height:20px;}
.mobGap{ padding:0px;}
.mob10{ width:10%!important;}
.mob20{ width:20%!important;}
.mob30{ width:30%!important;}
.mob40{ width:40%!important;}
.mob50{ width:50%!important;}
.mob60{ width:60%!important;}
.mob70{ width:70%!important;}
.mob80{ width:80%!important;}
.mob90{ width:90%!important;}
.mob25{ width:25%!important;}
.mob33{ width:33%!important;}
.mob75{ width:75%!important;}
.mobDisplayNone{ display:none;}
.mobRadius{ border:5px;}
.mobDisplayBlock{ display:block!important;}
.mobBorderNone{ border:none!important;}
.mobFloatnone{ float:none!important;}

.paddingRight{ padding-right:0px!important;}
.paddingLeft{ padding-left:0px!important;}
.paddinglftrgt{ padding-right:0px!important; padding-left:0px!important;}
.paddingTop{ padding-top:0px!important;}
.paddingBottom{ padding-bottom:0px!important;}
.paddingInBox{ padding:5px!important;}
.paddingInBoxExtra{ padding:10px!important;}
.padding-lg-lftrgt{padding: 7px!important;}
.mobPaddingInBox{ padding:5px!important;}
.mobPaddingInBoxExtra{ padding:10px!important;}
.mobPaddinglr{padding:0px 5px !important;}
.mobBorderNone{ border:0px!important;}


/*theme style*/
.headerSection{ padding:10px!important;}
.logo-panel{ margin-left:40px!important;}
.logo-panel img{ width:140px!important; padding-top: 4px;}
.logo-panel:after{ bottom:-50px; border-width:20px 35px; left:15px; display:none;}
.menuIcon{ display:block!important; position:absolute; left:15px; top:15px; cursor:pointer; width:30px; height:30px; background:url("https://uat.instaglobaltravel.com/assets/images/mobicon.png") 0 0/30px 30px scroll no-repeat;}
.soicalLinks{ margin-top:10px;}
.height209{ height:auto!important;}
.font30{font-size:22px; line-height:28px;}
.font28{font-size:20px; line-height:26px;}
.blogListing{ width:80%!important; margin:10px 8% 20px 12%!important; height:auto!important;}
.blogImg img{ height:100px!important;}
.navigation-panel{ display:none; background:rgba(255,255,255,1); position:absolute; top:59px; left:0px; z-index:10;}
.navigation-panel a{ width:100%; text-align:left; border-bottom:solid 1px #ccc; margin:0px 0px 0px!important;}
.navigation-panel a.active{background:none; color:#6c6c6c; border-radius:0px !important;}
.navigation-panel a:hover{ background: #f69321; color: #fff; border-radius: 0px; margin: 0 !important;}
ul.naviMenu li a:hover {color: #fff; background: #f69321; border-radius: 0px;}
ul.naviMenu li:hover ul {display: block; position: absolute;top: 42px;width: 100%;}
/*.navigation-panel a:nth-child(5){ display:none;}*/
.home-particles{ background-size:auto 100%!important; background-position:-400px 0px!important;}
.banner-panel{ padding:60px 20px 0px; padding-top:60px !important;}
#home{background:url("https://uat.instaglobaltravel.com/assets/images/mobbanner-bg2.jpg") 0px 0px/100% auto fixed no-repeat #333; background-position: 0px 0px !important; }
#home, .banner-panel{ min-height:460px;}
.font20{font-size:16px !important;  line-height:22px;}
.font16{font-size:14px; line-height:20px;}
.font15{font-size:12px;}
.themeBG{ background-size:auto 100%; background:#000 !important;}
.img-responsive{width:100%;}
.fav{right: 17px;}
.btn{font-size:13px;}
.font36{ font-size:22px!important; line-height:36px!important;}
.fav{ right:18px!important;}
.soicalLinks{ width:100%!important; margin:0px 0%!important;}
.leftMenu a{ width:50%!important; padding:5px 3px!important; font-size:12px!important; }
.leftMenu a i{margin-right:5px!important;}
.leftMenu a:last-child{border-bottom:solid 2px #ccc!important;}
img.borderAll.circle{ width:90px;}
/*.dashboardBox{ min-height:160px;}*/
.borderLeftWidth{ border-width:0px!important;}
.minHeight{ min-height:auto!important;}
.overflowScroll{ width:300px!important; overflow-y:scroll; float: none; margin: 0 auto;}
.minwidth600{ width:800px!important;}
.tabs-menu li{line-height: 22px !important;}
.tabs-menu li a{font-size: 11px !important;}
.tab-content{padding: 10px !important;}
.height380 {min-height:390px!important;}
.select2-container .select2-choice{padding: 11px !important;}
.select2-container .select2-choice .select2-arrow b{
background-size: 40px 36px !important;
background-image:none !important;
background: url("https://uat.instaglobaltravel.com/assets/images/arrow-dwn.png") no-repeat 0 7px !important;
}
.why-icon{width:50px;}
.mobfont11{	font-size:11px;}
.mobfont12{	font-size:12px;}
.mobfont16{ font-size:16px;}
.gapping{ padding: 20px 0px;}
.mobWhatsappIcon{ margin-right: 0px;}
.links{font-size: 12px;}
.blogImg { position: initial; width: 80%;}
.blogImg img { height: auto !important;}
.blogLeft { width: 100%;}
.blogListing::before{left: -3.3%;}
.blogListing::after {left: 52.5%;}
.marginTop80 { margin-top: 80px !important;}
.ac-container label{ font-size: 13px !important; line-height: 20px !important; padding: 15px 30px 15px 12px;}

.ac-container label:after,
.ac-container input:checked + label:after{
	background: transparent url("https://uat.instaglobaltravel.com/assets/images/plus-sm.png") no-repeat center center !important;
	right: 5px !important;
	width: 18px !important;
	height: 18px !important;
}
.ac-container input:checked + label:after{
	background-image: url("https://uat.instaglobaltravel.com/assets/images/minus-sm.png") !important;
}
.md-form{
	margin-top:10px;
}
.md-form label.active{
	height: 20px!important;
	padding: 1px 5px!important;
}

.md-form.md-outline label{
	top: 0px !important;
	line-height: 18px !important;
	}
.paddingInBoxExtra.mobPaddingNone{ padding:0px!important;}	

ul.naviMenu, ul.naviMenu li{ width:100%;}
ul.naviMenu li ul{ display:block; width:100%;}
ul.naviMenu li a{ padding:10px 15px!important;}
ul.naviMenu li ul li a{padding: 10px 10px !important;margin:0px!important;}
ul.naviMenu li:hover ul li a i {
  color: #ffbb6c;
  padding-right: 0;
}
.chat img{
	width: 110px!important;
}
.breadcrumb span.sep { display:none;}
.homemyreason .why-icon { width: 50px;height: auto;}
.homemyreason .mobfont12 { font-size: 14px;}
.homemyreason .gapping { padding-bottom:0;}
.testi {
  background: rgb(0, 0, 0);
  border: 1px solid #b8b3b3;
  border-radius: 10px;
  padding: 20px;
  min-height: auto;
  margin: 0 0 10px;
}
.testimonailhome .row.gapping.paddingBottomNone { padding: 0;}
h1.resp-title {font-size: 20px;line-height: normal;margin: 11px 0;}
.resp-repeatcus { position:relative!important; margin:5px 0!important;}
.contact-form .row .col-sm-2 { width:100%!important;}
.contact-form .row .col-sm-8 { width:100%!important;}
.contact-form .row .colisdcode { width:45%!important;}
.contact-form .row .colcontact { width:53%!important;}

.blog-read img{ height:auto;}

}

@media all and (max-width:360px){
	.logo-panel img {
    width: 100px!important;
	padding-top: 7px;
}
.chat img{
	width: 110px!important;
}
.soicalLinks {
    width: 100%!important;
    margin: 0px 0%!important;
}
.leftMenu a { width: 100%!important;}
.mob-xs-Row{ width:100% !important;}
.mob-xs-10{width:10%!important;}
.mob-xs-20{width:20%!important;}
.mob-xs-30{width:30%!important;}
.mob-xs-40{width:40%!important;}
.mob-xs-50{width:50%!important;}
.mob-xs-60{width:60%!important;}
.mob-xs-70{width:70%!important;}
.mob-xs-80{width:80%!important;}
.mob-xs-90{width:90%!important;}
.paddingInBoxExtraNone{padding:0px !important;}
.overflowScroll { width: 270px !important;}
.font13{ font-size:11px;}

}





/*ipad*/
@media all and (min-width:768px) and (max-width:1024px){
body{font-size: 13px;}
.wrap{ width:100%!important; display:table;}
.tabRow{ width:100%;}
.tab1{ width:10%; min-height:1px;}
.tab2{ width:20%; min-height:1px;}
.tab3{ width:30%; min-height:1px;}
.tab4{ width:40%; min-height:1px;}
.tab5{ width:50%; min-height:1px;}
.tab6{ width:60%; min-height:1px;}
.tab7{ width:70%; min-height:1px;}
.tab8{ width:80%; min-height:1px;}
.tab9{ width:90%; min-height:1px;}
.tab1Forth{ width:25%; min-height:1px;}
.tab1Third{ width:33.33%; min-height:1px;}
.tab3Forth{ width:75%; min-height:1px;}
.tabpaddingTopNone{	padding-top:0px;}
.tabpaddingRightNone{padding-right:0px;}
.tabpaddingInBox{ padding:10px !important;}
.padding-lg-lftrgt { padding: 10px;}
.tabmarginInBox{ margin:10px;}
.gapping{padding: 20px 0px;}
.marginTop80 { margin-top: 70px !important;}
.font36 { font-size: 30px; line-height: 36px;}
.font28 { font-size: 25px; line-height: 32px;}
.font16 { font-size: 14px; line-height: 20px;}
.logo-panel img { width: 140px !important;}
ul.naviMenu li a {padding: 5px 8px; font-size: 12px;}
.navigation-panel a{ padding:5px 8px; font-size: 12px;}
.home-particles { background-size: auto 100% !important; background-position: -400px 0px !important;}
.links { font-size: 13px;}
.fav {right: 35px; z-index: 1;}
.excel { padding: 12px 12px; font-size: 14px;}
.content{font-size: 13px;}
.content ul li{padding: 1px 0px;}
.circle{width:60px;}
.invalid-feedback{margin-bottom: 0px;}
.blogListing{ height: 400px; margin: 10px 2% 30px 4%;}
.blogImg { position: initial; width: 80%;}
.blogImg img { height: auto !important;}
.blogLeft { width: 100%;}
.blogListing::before{left: -3.3%;}
.blogListing::after {left: 52.5%;}
.ac-container label{font-size: 13px !important; padding: 12px 45px 12px 10px;}
.tabdashMin{min-height: 563px;}
.overflowScroll { width: 100% !important; overflow-y: scroll;}
.minwidth600 { min-width: 600px !important;}
.btn{padding: 5px 6px;}
.marginTop40 { margin-top: 75px !important;}
}
